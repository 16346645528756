<template>
  <div class="allCommandAuditLoginList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(audits)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(audits)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="installationUrl"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <router-link
              :to="`/installation/${ props.dataItem.installationId }/software-components/${props.dataItem.parentId}`"
              class="alt-primary-color"
            >
              {{ kgm_getNestedValue(props.field, props.dataItem) }}
            </router-link>
          </td>
        </template>
        <template
          slot="dateTemplate"
          slot-scope="{props}"
        >
          <td>
            <span>{{ dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem)) }}</span>
          </td>
        </template>
        <div
          slot="logDateFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="logDateFilterInput"
            :value="props.value"
            type="date"
            placeholder="Filter by Date"
            class="form-control mr-2 rounded-right"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('logDateFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="textFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="textFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('textFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
      </Grid>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: "AllCommandAuditLoginList",
  mixins: [
    kendoGridMixin,
    dateTimeMixin
  ],
  props: {
    from: {
      type: String,
      required: false,
      default () {
        return null;
      },
    },
    to: {
      type: String,
      required: false,
      default () {
        return null;
      },
    }
  },
  data () {
    return {
      loading: true,
      audits: null,
      kgm_columns: [
        {
          field: 'installationId',
          filterable: true,
          filter: 'text',
          title: 'InstallationId',
          filterCell: 'textFilter',
          cell: 'installationUrl'
        },
        {
          field: 'logDate',
          filterable: true,
          filter: 'date',
          title: this.$t('createDate'),
          cell: 'dateTemplate',
          filterCell: 'logDateFilter',
        },
        {
          field: 'username',
          filterable: true,
          filter: 'text',
          title: this.$t('deviceDetailComponent.createdBy'),
          filterCell: 'textFilter'
        },
        {
          field: 'newValue',
          filterable: true,
          filter: 'text',
          title: this.$t('type'),
          filterCell: 'textFilter'
        },
        {
          field: 'oldValue',
          filterable: true,
          filter: 'text',
          title: this.$t('deviceCommandDialogExecuteComponent.command'),
          filterCell: 'textFilter'
        }
      ]
    }
  },
  metaInfo () {
    return {
      title: 'Audit Logs'
    }
  },
  methods: {
    searchCommandAudits () {
      this.loading = true;
      let url = `/DatabaseAuditLog/GetCommandAudits?from=${this.from}&to=${this.to}`;
      this.axios.get(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.audits = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>
<style>
.applicationSettingsList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>